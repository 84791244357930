import React, { FC } from 'react';
import SectionTitle from '../../../components/common/SectionTitle';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import ListItem from '../../../components/pagesComponents/CaseStudy/ListItem';
import { TText } from '../../../types/types';
import StatfloLayout from '../../../components/PagesLayouts/StatfloLayout';
import FixedButton from '../../../components/common/FixedButton';

const OtherIndustriesRelevance: FC = () => {
    const industriesText: TText = [
        { id: 0, text: 'Any event-based systems', margin: 'mt' },
        { id: 1, text: 'Powerful in-house chat systems' },
        { id: 2, text: 'Chatbots' },
        { id: 3, text: 'Multithreaded chats management systems' },
        { id: 4, text: 'SMS outreach SaaS products' },
        { id: 5, text: 'Mailing list management' },
        { id: 6, text: 'Partly as a CRM modules' },
        { id: 7, text: 'Enterprise-level complicance & security checks' },
        { id: 8, text: 'Keeping under control anti-spam goverment regulations' },
    ];

    return (
        <StatfloLayout>
            <div className="statflo-case-study__text statflo-case-study__text--large">
                <SectionTitle type="primary" title="Others industries relevance" />
                {industriesText.map((industry) => (
                    <ListItem key={industry.id} margin={industry.margin}>
                        {industry.text}
                    </ListItem>
                ))}

                <Links />
            </div>
            <FixedButton/>
        </StatfloLayout>
    );
};

export default OtherIndustriesRelevance;
