import React, { FC } from 'react';
import { IListItemProps } from '../../../../types/types';

import './ListItem.scss';

const ListItem: FC<IListItemProps> = (props) => {
    return (
        <p data-testid="list-item" className={`list-item list-item--${props.margin}`}>
            {props.children}
        </p>
    );
};

export default ListItem;
